import { API } from '../axios-config';

export interface ISubscription {
  name: string;
  email: string;
}

const subscribe = async (req: ISubscription, token: string): Promise<void | Error> => {
  try {
    await API.post<ISubscription>('/subscription', req, {
      headers: {
        'X-Token': token,
      },
    });
  } catch (error) {
    return new Error((error as { message: string }).message || 'Unexpected error on create. Try again later.');
  }
};

export const SubscriptionService = {
  subscribe,
};
