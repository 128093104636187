import { Grid } from '@mui/material';
import InstagramIcon from '../../../assets/images/icone-instagram.png';

export const IconInstagram = () => {
  return (
    <Grid item display={'flex'} alignItems={'center'} justifyContent={'initial'}>
      <img src={InstagramIcon} style={{ width: '30px' }} alt="instagram-icone" />
    </Grid>
  );
};
