import { createTheme } from '@mui/material';

export const LightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#6C63FF',
      dark: '#5147FF',
      light: '#9E99FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F26CA7',
      dark: '#DC6298',
      light: '#F4B1CE',
      contrastText: '#FFFFFF',
    },
    background: {
      paper: '#FFFFFF',
      default: '#F6F5F5',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: '#FFFFFF',
        textPrimary: '#FFFFFF',
        textSecondary: '#FFFFFF',
        textInherit: '#FFFFFF',
      },
    },
  },
  typography: {
    button: {
      fontWeight: 300,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 500,
    },
    h4: {
      fontSize: '38px',
    },
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
});
