import { useTheme, Grid } from '@mui/material';
import imageManAndEnvelope from '../../../assets/images/imagem-lp-homem-e-envelope.png';

export const ManAndEnvelopeImage = () => {
  const theme = useTheme();

  const centerByBreakpoint = {
    [theme.breakpoints.between('xs', 'lg')]: {
      display: 'grid',
      justifyContent: 'center',
      alignContent: 'center',
    },
  } as const;

  return (
    <Grid item sx={centerByBreakpoint}>
      <img src={imageManAndEnvelope} alt="imagem-homem-e-envelope" width="100%" height="100%" />
    </Grid>
  );
};
