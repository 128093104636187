import React, { useRef, useState, useCallback, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { useTheme, Button, Grid, Typography, Divider, Link } from '@mui/material';
import { ManAndEnvelopeImage, IconCycle, IconRaffle, IconSendCard, IconMailBox, HeaderLandingPage, FooterLandingPage } from '../../shared/components';
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';
import { SubscriptionService, ISubscription } from '../../shared/services/api/subscription/SubscriptionService';
import { EventTrackingService } from '../../shared/services/ga/EventTrackingService';

declare module 'yup' {
  interface StringSchema {
    reCaptcha(): StringSchema;
  }
}

export const LandingPage: React.FC = () => {
  const theme = useTheme();

  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [hasReCaptchaErrors, setHasReCaptchaErrors] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const captchaRef = useRef<ReCAPTCHA>(null);

  const gaCategory = 'landing-page';

  useEffect(() => {
    const fromLocal = JSON.parse(localStorage.getItem('isSaved') || 'false');

    if (fromLocal) {
      setIsSaved(fromLocal);
    } else {
      localStorage.setItem('isSaved', JSON.stringify(isSaved));
    }
  }, [isSaved]);

  yup.addMethod(yup.string, 'reCaptcha', function (errorMessage) {
    return this.test('test-reCaptcha', errorMessage, function () {
      const { path, createError } = this;
      const token = captchaRef.current?.getValue();

      if (token == undefined || token == '') {
        setHasReCaptchaErrors(true);
      } else {
        setHasReCaptchaErrors(false);
      }

      return token !== '' || createError({ path, message: errorMessage });
    });
  });

  const formValidationSchema: yup.SchemaOf<ISubscription> = yup.object().shape({
    name: yup.string().min(3).required('Preencha seu nome'),
    email: yup.string().email('Seu e-mail é inválido').required('Preencha seu e-mail'),
    recaptcha: yup.string().reCaptcha(),
  });

  const handleSave = (data: ISubscription) => {
    formValidationSchema
      .validate(data, { abortEarly: false })
      .then((validatedData) => {
        const token = captchaRef.current?.getValue() || '';
        setIsLoading(true);

        SubscriptionService.subscribe(validatedData, token).then((result) => {
          captchaRef.current?.reset();
          save();
          formRef.current?.setData({
            email: '',
            name: '',
          });

          if (result instanceof Error) {
            setHasErrors(true);
            EventTrackingService.track({
              category: gaCategory,
              action: 'quero-fazer-parte-clique',
              label: 'erro-na-requisicao',
            });
          } else {
            EventTrackingService.track({
              category: gaCategory,
              action: 'quero-fazer-parte-clique',
              label: 'sucesso',
            });
            setIsSaved(true);
            localStorage.setItem('isSaved', JSON.stringify(isSaved));
          }

          setIsLoading(false);
        });
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach((error) => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);

        EventTrackingService.track({
          category: gaCategory,
          action: 'quero-fazer-parte-clique',
          label: 'erro-de-validacao',
        });
      });
  };

  const changeRecaptcha = () => {
    const token = captchaRef.current?.getValue();

    if (token == undefined || token == '') {
      setHasReCaptchaErrors(true);
      setIsLoading(false);
    } else {
      setHasReCaptchaErrors(false);
    }
  };

  const itemGap = {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  } as const;

  const captcha = {
    border: hasReCaptchaErrors ? '1px solid #d32f2f' : 'none',
    borderRadius: hasReCaptchaErrors ? '4px' : 'none',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
  } as const;

  return (
    <>
      <HeaderLandingPage />
      <Grid
        container
        sx={{
          pt: {
            xs: theme.spacing(16),
          },
          pb: {
            xs: theme.spacing(16),
          },
          pr: {
            xs: theme.spacing(8),
          },
          pl: {
            xs: theme.spacing(8),
          },
          gap: {
            lg: theme.spacing(22),
          },
        }}>
        <Grid container minHeight="80vh" alignContent="center">
          <Grid item md={12} lg={7} sx={itemGap}>
            <Grid item lg={8} sx={itemGap}>
              <Grid item sx={itemGap}>
                <Typography variant="h3" fontWeight="500">
                  Mande um Cartão:
                </Typography>
                <Typography variant="h4">Venha se conectar com pessoas de uma forma mais especial que uma mensagem de texto ou um e-mail</Typography>
                <Divider sx={{ border: '1px solid gray', width: '8%' }} />
              </Grid>
              <Grid container gap={3} pt={12} alignContent="end">
                <Button
                  color="primary"
                  variant="contained"
                  href="#participar"
                  onClick={() =>
                    EventTrackingService.track({
                      category: gaCategory,
                      action: 'participe-clique',
                      label: 'redirecionamento',
                    })
                  }>
                  PARTICIPE
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  href="#como-funciona"
                  onClick={() =>
                    EventTrackingService.track({
                      category: gaCategory,
                      action: 'leia-mais-clique',
                      label: 'redirecionamento',
                    })
                  }>
                  LEIA MAIS
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            lg={5}
            sx={{
              padding: {
                xs: theme.spacing(4),
              },
            }}>
            <ManAndEnvelopeImage />
          </Grid>
        </Grid>
        <Grid id="como-funciona" container minHeight="80vh">
          <Grid container direction="column" alignItems="center" textAlign="center">
            <Typography variant="h4">Como funciona</Typography>
            <Divider variant="middle" sx={{ border: '1px solid gray', width: '4%', margin: '20px' }} />
          </Grid>
          <Grid
            container
            justifyContent="center"
            sx={{
              gap: {
                xs: theme.spacing(3),
                md: 0,
              },
            }}>
            <Grid item textAlign="center" alignItems="center" xs={12} md={3} sx={itemGap}>
              <Grid item>
                <IconRaffle />
              </Grid>
              <Typography variant="h5">
                <strong>Sorteie</strong>
                <br /> seu novo amigo
              </Typography>
              <Grid item xs={10}>
                <Typography variant="body2" color="gray">
                  Você receberá o perfil completo desse amigo, com endereço, rede social e interesses. Assim, poderá personalizar o cartão da melhor maneira
                  possível.
                </Typography>
              </Grid>
            </Grid>
            <Grid item textAlign="center" alignItems="center" xs={12} md={3} sx={itemGap}>
              <Grid item>
                <IconSendCard />
              </Grid>
              <Typography variant="h5">
                <strong>Envie</strong>
                <br /> seu cartão
              </Typography>
              <Grid item xs={10}>
                <Typography variant="body2" color="gray">
                  Depois de enviar o seu cartão conte para a gente como quer se tornar elegível para também ser sorteado: faça o upload do seu comprovante ou
                  aguarde seu amigo confirmar o recebimento.
                </Typography>
              </Grid>
            </Grid>
            <Grid item textAlign="center" alignItems="center" xs={12} md={3} sx={itemGap}>
              <Grid item>
                <IconMailBox />
              </Grid>
              <Typography variant="h5">
                <strong>Pronto</strong>
                <br /> você será sorteado
              </Typography>
              <Grid item xs={10}>
                <Typography variant="body2" color="gray">
                  Você agora está elegível para receber um cartão também! Logo logo seu nome será sorteado e seu cartão estará a caminho do endereço cadastrado!
                </Typography>
              </Grid>
            </Grid>
            <Grid item textAlign="center" alignItems="center" xs={12} md={3} sx={itemGap}>
              <Grid item>
                <IconCycle />
              </Grid>
              <Typography variant="h5">
                <strong>Começe</strong>
                <br /> tudo de novo
              </Typography>
              <Grid item xs={10}>
                <Typography variant="body2" color="gray">
                  Agora você pode sortear mais um novo amigo, produzir mais um lindo cartão e presentear mais alguém com um abraço em forma de envelope.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container minHeight={isSaved ? '40vh' : '60vh'} id="participar">
          <Grid container direction="column" alignItems="center" textAlign="center" pt={4}>
            <Typography variant="h4">{isSaved ? 'Muito obrigado :)' : 'Ficou interessado?'}</Typography>
            <Divider sx={{ border: '1px solid gray', width: '4%', margin: '20px' }} />
          </Grid>
          <Grid container justifyContent="center" textAlign="center">
            <Grid item md={5}>
              <Typography display={isSaved ? '' : 'none'} variant="h5" fontWeight="300">
                Estamos muito empolgados com essa ideia e ficamos felizes que você quer fazer parte. Fique de olho no seu e-mail que entraremos em contato em
                breve.
              </Typography>
              <Typography display={isSaved ? 'none' : ''} variant="h5" fontWeight="300">
                Estamos trabalhando para transformar essa ideia em realidade. Siga nosso perfil no&nbsp;
                <Link
                  href="https://www.instagram.com/mandeumcartao/"
                  target="_blank"
                  onClick={() =>
                    EventTrackingService.track({
                      category: gaCategory,
                      action: 'instagram-clique',
                      label: 'redirecionamento',
                    })
                  }>
                  Instagram
                </Link>
                &nbsp;e faça o pré-cadastro abaixo para participar das primeiras rodadas de teste.
              </Typography>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" display={isSaved ? 'none' : ''}>
              <Grid item md={3}>
                <Grid
                  item
                  display={hasErrors ? '' : 'none'}
                  sx={{ mt: '15px', pt: '15px', pb: '15px', backgroundColor: 'rgba(255, 0, 0, 0.1)', border: '1px solid #9B0000', borderRadius: '4px' }}>
                  <Typography sx={{ opacity: '0.9', color: 'rgba(255, 0, 0, 0.7)' }}>
                    Infelizmente não conseguimos processar sua solicitação. Tente novamente em alguns instantes.
                  </Typography>
                </Grid>
                <VForm ref={formRef} onSubmit={handleSave}>
                  <VTextField fullWidth label="Seu nome" name="name" margin="normal" disabled={isLoading} />
                  <VTextField fullWidth label="Seu melhor e-mail" name="email" margin="normal" disabled={isLoading} />
                  <Grid container justifyContent="center" alignItems="center" mt={2}>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      ref={captchaRef}
                      onChange={useCallback(() => changeRecaptcha(), [])}
                      style={captcha}
                      hl="pt-BR"
                    />
                    <Typography variant="caption" fontWeight="400" color="#d32f2f" display={hasReCaptchaErrors ? '' : 'none'}>
                      Informe que você não é um robo
                    </Typography>
                  </Grid>
                  <Grid container mt={3}>
                    <Button fullWidth variant="contained" type="submit" disabled={isLoading}>
                      QUERO FAZER PARTE!
                    </Button>
                  </Grid>
                </VForm>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FooterLandingPage />
    </>
  );
};
