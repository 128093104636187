import { useState } from 'react';
import { useTheme, Grid, Typography, Button, Link, TextField } from '@mui/material';

import { useForm, SubmitHandler } from 'react-hook-form';

import HomePicture from '../../assets/images/imagem-homem-e-envelope.png';

import { HeaderHome, Footer } from '../../shared/components';

interface IFormInputs {
  cep: string;
  estadoUF: string;
  cidade: string;
  bairro: string;
  pais: string;
  endereço: string;
  number: string;
  complemento: string;
}

export const CadastrarEndereco = () => {
  const theme = useTheme();

  const onSubmit: SubmitHandler<IFormInputs> = (data) => console.log(data);
  const [cep, setCep] = useState('');

  const { register, handleSubmit } = useForm<IFormInputs>();

  return (
    <>
      <Grid container overflow={'hidden'} style={{ minHeight: '100vh' }}>
        <Grid container alignContent={'space-between'}>
          <HeaderHome />

          <Grid container justifyContent={'center'}>
            <Typography overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipses'} variant={'body2'} fontSize={'1.8rem'}>
              Register address
            </Typography>
          </Grid>

          <Grid
            container
            bgcolor={'transparent'}
            justifyContent={'center'}
            style={{
              backgroundColor: theme.palette.background.paper,
              backgroundImage: `url(${HomePicture})`,
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
            }}>
            <Grid
              container
              direction={'row'}
              justifyContent={'center'}
              xs={6}
              minHeight={theme.spacing(40)}
              borderRadius={theme.spacing(2)}
              padding={theme.spacing(4)}
              marginBottom={theme.spacing(2)}
              bgcolor={theme.palette.background.default}
              gap={2}>
              <Grid container justifyContent={'center'}>
                <form onSubmit={handleSubmit(onSubmit)} style={{ width: '80%' }}>
                  <Grid container marginX={theme.spacing(8)} gap={1}>
                    <Grid item>
                      <Typography variant={'body2'} fontSize={'1.1rem'}>
                        Cep
                      </Typography>
                      <TextField
                        required
                        type={'text'}
                        {...register('cep', { required: true })}
                        value={cep.replace(/[^\d]/, '')}
                        size={'small'}
                        onChange={(e) => setCep(e.target.value)}
                        placeholder={'_____-___'}
                        sx={{
                          width: '7rem',
                        }}
                        inputProps={{ minLength: 8, maxLength: 8 }}
                      />
                    </Grid>
                    <Grid container gap={2}>
                      <Grid item>
                        <Typography variant={'body2'} fontSize={'1.1rem'}>
                          Estado
                        </Typography>
                        <TextField
                          required
                          size={'small'}
                          placeholder={'São Paulo'}
                          sx={{
                            width: '10rem',
                          }}
                          inputProps={{ maxLength: 11 }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant={'body2'} fontSize={'1.1rem'}>
                          Cidade
                        </Typography>
                        <TextField
                          required
                          size={'small'}
                          placeholder={'Nova Odessa'}
                          sx={{
                            width: '14rem',
                          }}
                          inputProps={{ maxLength: 30 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container gap={2}>
                      <Grid item>
                        <Typography variant={'body2'} fontSize={'1.1rem'}>
                          Bairro
                        </Typography>
                        <TextField
                          required
                          size={'small'}
                          placeholder={'Jardim das Flores'}
                          sx={{
                            width: '12rem',
                          }}
                          inputProps={{ maxLength: 25 }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant={'body2'} fontSize={'1.1rem'}>
                          País
                        </Typography>
                        <TextField
                          required
                          size={'small'}
                          placeholder={'Brasil'}
                          sx={{
                            width: '8rem',
                          }}
                          inputProps={{ maxLength: 14 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container gap={2}>
                      <Grid item>
                        <Typography variant={'body2'} fontSize={'1.1rem'}>
                          Endereço
                        </Typography>
                        <TextField
                          required
                          size={'small'}
                          placeholder={'Rua Antropólis'}
                          sx={{
                            width: '16rem',
                          }}
                          inputProps={{ maxLength: 30 }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant={'body2'} fontSize={'1.1rem'}>
                          N°
                        </Typography>
                        <TextField
                          required
                          size={'small'}
                          placeholder={'825A'}
                          sx={{
                            width: '6rem',
                          }}
                          inputProps={{ maxLength: 6 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant={'body2'} fontSize={'1.1rem'}>
                        Complemento
                      </Typography>
                      <TextField
                        required
                        size={'small'}
                        placeholder={'Casa'}
                        sx={{
                          width: '9rem',
                        }}
                        inputProps={{ maxLength: 12 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container marginTop={theme.spacing(2)} justifyContent={'center'} alignContent={'end'}>
                    <Grid item width={'80%'}>
                      <Button
                        type={'submit'}
                        size={'large'}
                        variant={'contained'}
                        href={'/compartilhar-conclusao'}
                        LinkComponent={Link}
                        sx={{
                          width: '100%',
                          color: 'white',
                          textTransform: 'capitalize',
                          fontSize: '1rem',
                        }}>
                        Register address
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignContent={'end'}>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
