import { BrowserRouter } from 'react-router-dom';
import { DrawerProvider } from './shared/contexts';
// import { SideMenu } from './shared/components';
import { AppRoutes } from './routes';
import { ThemeProvider } from '@mui/material';
import { LightTheme } from './shared/themes';

export const App = () => {
  return (
    <ThemeProvider theme={LightTheme}>
      <DrawerProvider>
        <BrowserRouter>
          {/*<SideMenu>*/}
          <AppRoutes />
          {/*</SideMenu>*/}
        </BrowserRouter>
      </DrawerProvider>
    </ThemeProvider>
  );
};
