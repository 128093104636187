import { Grid } from '@mui/material';
import iconMailBox from '../../../assets/images/icone-caixa-de-correio.png';

export const IconMailBox = () => {
  return (
    <Grid>
      <img src={iconMailBox} alt="imagem-homem-e-envelope" width="150px" />
    </Grid>
  );
};
