import { Grid } from '@mui/material';
import iconSendCard from '../../../assets/images/icone-envelope-carimbo.png';

export const IconSendCard = () => {
  return (
    <Grid item>
      <img src={iconSendCard} alt="icone-envio-cartao" width="150px" />
    </Grid>
  );
};
