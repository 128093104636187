import { Grid, Typography } from '@mui/material';

export const TextAbout = () => {
  const Pr = {
    pr1: 'Quando eu era novinha, meus pais me inscreveram em um Pen Pal Club:. Daí eu recebi o endereço de 5 pessoas aleatórias, de diferentes lugares do mundo e comecei a trocar correspondências com elas. Até hoje eu me lembro da ansiedade quer era a espera do carteiro chegar, imaginando se ele vinha com uma cartinha nova para mim.',
    pr2: 'Isso faz bastante tempo... E desde então, emails e mensagens de texto levaram embora esse sentimento: se, por um lado, a mensagem chega muito mais rápidamente ao destino, de outro, ela  é bem mais fria e impessoal.',
    pr3: 'A partir dessa lembrança, no meio da pandemia, quando todo mundo sentia uma falta enrome de abraços e contato, eu resolvi reviver essa prática: na época do Natal, fiz um “amigo-secreto” no qual o presente era obrigatoriamente seria um cartão de Natal, enviado pelo correio. Várias pessoas participaram. Todas elas adoraram!',
    pr4: 'Esse gesto simples (enviar um cartão pelo correio) espalhou sorrisos, e a cada depoimento de quem recebia seu cartão, eu revivi a mesma alegria que a criança em mim sentiu quando abria as cartas do Pen Pal.',
    pr5: 'Foi dessa ideia que o Mande ser Cartão surgiu: queremos trazer de volta a alegria de receber, pelo correio um cartão escrito a mão, de alguém do outro lado do país (ou do mundo!).',
    pr6: 'Estamos tão acostumados a apenas apertar um botão “send/enviar” com um texto corrido, ou um emoji, ou uma figurinha, que nem sequer nos lembramos mais de como é especial saber que alguém se disponibilizou a escolher ou confeccionar um cartão, e escrever (literalmente escrever ao invés de digitar) algumas palavras. E mais! Ouso dizer que muita gente nem sequer sabe o quanto é prazeroso receber pelo correio uma correspondência que não seja um boleto ou uma propaganda.',
    pr7: 'Nosso objetivo é trazer de volta a sensação de abraço que um cartão pode proporcionar. Esperamos que você se sinta abraçado!',
    pr8: 'Luciana Pimenta',
  };

  return (
    <>
      <Grid item xs={10} display={'flex'} flexDirection={'column'} gap={2}>
        <Typography paragraph textAlign={'center'}>
          {Pr.pr1}
        </Typography>
        <Typography paragraph textAlign={'center'}>
          {Pr.pr2}
        </Typography>
        <Typography paragraph textAlign={'center'}>
          {Pr.pr3}
        </Typography>
        <Typography paragraph textAlign={'center'}>
          {Pr.pr4}
        </Typography>
        <Typography paragraph textAlign={'center'}>
          {Pr.pr5}
        </Typography>
        <Typography paragraph textAlign={'center'}>
          {Pr.pr6}
        </Typography>
        <Typography paragraph textAlign={'center'}>
          {Pr.pr7}
        </Typography>
        <Typography paragraph textAlign={'center'}>
          {Pr.pr8}
        </Typography>
      </Grid>
    </>
  );
};
