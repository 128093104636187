import { useTheme, Grid, Typography, Divider, Button } from '@mui/material';

import { HeaderLandingPage, FooterLandingPage, IconLinkedin, IconGitHub, IconInstagram } from '../../shared/components';

import LucianaFoto from '../../assets/images/foto-luciana.png';
import GustavoFoto from '../../assets/images/foto-gustavo.png';
import EduardoFoto from '../../assets/images/foto-eduardo.png';

export const TeamPage = () => {
  const theme = useTheme();

  const ProfilesURL = {
    EduardoCauaGitHub: 'https://github.com/eduardo-caua',
    EduardoCauaLinkedin: 'https://www.linkedin.com/in/eduardocauasantos/',
    LucianaPimentaInstagram: 'https://www.instagram.com/lully.pimenta/',
    LucianaPimentaLinkedin: 'https://www.linkedin.com/in/luciana-pimenta-0694502a/',
    GustavoMartinsGitHub: 'https://github.com/Gustavo-Leite',
    GustavoMartinsLinkedin: 'https://www.linkedin.com/in/gustavo-martins-leite-295885207/',
    HenriqueMagnoGitHub: 'https://github.com/HenriqueMagno',
    HenriqueMagnoLinkedin: 'https://www.linkedin.com/in/henrique-magno-a2030a1b2/',
  };

  return (
    <>
      <HeaderLandingPage />
      <Grid
        container
        sx={{
          pt: {
            xs: theme.spacing(16),
          },
          pb: {
            xs: theme.spacing(16),
          },
        }}>
        <Grid container minHeight={'80vh'} justifyContent={'center'} alignContent={'center'}>
          <Grid container flexDirection={'column'} alignItems={'center'} textAlign={'center'}>
            <Typography variant="h3" fontWeight="500" paragraph>
              Time
            </Typography>
            <Divider variant={'middle'} sx={{ border: '1px solid gray', width: '4%', margin: '20px' }} />
          </Grid>

          <Grid container justifyContent={'center'}>
            <Grid
              item
              xs={12}
              xl={8}
              display={'flex'}
              justifyContent={'center'}
              sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'center' }, mt: { sm: theme.spacing(8) } }}>
              {/* Luciana */}
              <Grid
                item
                display={'flex'}
                flexDirection={'column'}
                sm={4}
                justifyContent={'end'}
                bgcolor={'#8A83F9'}
                sx={{
                  width: {
                    xs: theme.spacing(42),
                  },
                  height: {
                    xs: theme.spacing(50),
                    sm: theme.spacing(52),
                  },
                  mt: {
                    xs: theme.spacing(12),
                    sm: theme.spacing(8),
                    md: theme.spacing(6),
                  },
                }}>
                <Grid item display={'flex'} justifyContent={'center'}>
                  <img src={LucianaFoto} alt="foto-luciana" width="200px" />
                </Grid>
                <Grid item p={2} gap={2} display={'flex'} flexDirection={'column'} alignItems={'center'} bgcolor={'#F2F2F2'}>
                  <Grid item>
                    <Typography variant="h6" align="center">
                      Luciana Pimenta
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color={'#616161'}>
                      Fundadora
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button href={`${ProfilesURL.LucianaPimentaInstagram}`}>
                      <IconInstagram />
                    </Button>
                    <Button href={`${ProfilesURL.LucianaPimentaLinkedin}`}>
                      <IconLinkedin />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {/* Eduardo */}
              <Grid
                item
                display={'flex'}
                flexDirection={'column'}
                sm={4}
                justifyContent={'end'}
                bgcolor={'#8A83F9'}
                sx={{
                  width: {
                    xs: theme.spacing(42),
                  },
                  height: {
                    xs: theme.spacing(50),
                    sm: theme.spacing(52),
                  },
                  mt: {
                    xs: theme.spacing(12),
                    sm: theme.spacing(8),
                    md: theme.spacing(6),
                  },
                }}>
                <Grid item display={'flex'} justifyContent={'center'}>
                  <img src={EduardoFoto} alt="foto-eduardo" width="250px" />
                </Grid>
                <Grid item p={2} gap={2} display={'flex'} flexDirection={'column'} alignItems={'center'} bgcolor={'#F2F2F2'}>
                  <Grid item>
                    <Typography variant="h6" align="center">
                      Eduardo Cauã
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color={'#616161'}>
                      Desenvolvedor
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button href={`${ProfilesURL.EduardoCauaLinkedin}`}>
                      <IconLinkedin />
                    </Button>
                    <Button href={`${ProfilesURL.EduardoCauaGitHub}`}>
                      <IconGitHub />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {/* Gustavo */}
              <Grid
                item
                display={'flex'}
                flexDirection={'column'}
                sm={4}
                justifyContent={'end'}
                bgcolor={'#8A83F9'}
                sx={{
                  width: {
                    xs: theme.spacing(42),
                  },
                  height: {
                    xs: theme.spacing(50),
                    sm: theme.spacing(52),
                  },
                  mt: {
                    xs: theme.spacing(12),
                    sm: theme.spacing(8),
                    md: theme.spacing(6),
                  },
                }}>
                <Grid item display={'flex'} justifyContent={'center'}>
                  <img src={GustavoFoto} alt="foto-gustavo" width="180px" />
                </Grid>
                <Grid item p={2} gap={2} display={'flex'} flexDirection={'column'} alignItems={'center'} bgcolor={'#F2F2F2'}>
                  <Grid item>
                    <Typography variant="h6" align="center">
                      Gustavo Martins
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color={'#616161'}>
                      Desenvolvedor
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button href={`${ProfilesURL.GustavoMartinsLinkedin}`}>
                      <IconLinkedin />
                    </Button>
                    <Button href={`${ProfilesURL.GustavoMartinsGitHub}`}>
                      <IconGitHub />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FooterLandingPage />
    </>
  );
};
