import ReactGA from 'react-ga';

export interface IEvent {
  category: string;
  action: string;
  label: string;
  value?: number;
}

const track = (event: IEvent): Promise<void | Error> => {
  const eventTracker = new Promise<void | Error>((resolve, reject) => {
    try {
      ReactGA.event(event);
      resolve();
    } catch (error) {
      reject();
      return new Error((error as { message: string }).message || 'Unexpected error on tracking event. Try again later.');
    }
  });

  return eventTracker;
};

export const EventTrackingService = {
  track,
};
