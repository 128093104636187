import { Grid, Button, Link, useTheme } from '@mui/material';

export const Footer = () => {
  const theme = useTheme();

  return (
    <Grid container bgcolor={'primary.main'} justifyContent={'center'} gap={8} maxHeight={theme.spacing(3.8)}>
      <Grid item>
        <Button
          variant={'text'}
          size={'small'}
          href={'/dashboard'}
          sx={{
            color: 'white',
            textTransform: 'capitalize',
            fontSize: '1rem',
          }}>
          Política de Privacidade
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant={'text'}
          size={'small'}
          href={'/dashboard'}
          LinkComponent={Link}
          sx={{
            color: 'white',
            textTransform: 'capitalize',
            fontSize: '1rem',
          }}>
          Mande um Cartão &reg; {new Date().getFullYear()}
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant={'text'}
          size={'small'}
          href={'/dashboard'}
          sx={{
            color: 'white',
            textTransform: 'capitalize',
            fontSize: '1rem',
          }}>
          Termos de uso
        </Button>
      </Grid>
    </Grid>
  );
};
