import { API } from '../axios-config';

export interface IContact {
  name: string;
  email: string;
  reason: string;
  message: string;
}

const create = async (req: IContact, token: string): Promise<void | Error> => {
  try {
    await API.post<IContact>('/contact', req, {
      headers: {
        'X-Token': token,
      },
    });
  } catch (error) {
    return new Error((error as { message: string }).message || 'Unexpected error on create. Try again later.');
  }
};

export const ContactService = {
  create,
};
