import { useTheme, Grid, Typography, Button, Link, Divider } from '@mui/material';

import HomePicture from '../../assets/images/imagem-homem-e-envelope.png';
import Planet from '../../assets/images/planet.png';

import { HeaderHome, LinearStepper, Footer } from '../../shared/components';

export const ReceberCartao = () => {
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        overflow={'hidden'}
        style={{
          minHeight: '100vh',
        }}>
        <Grid container alignContent={'space-between'}>
          <HeaderHome />

          <Grid container justifyContent={'center'}>
            <Typography overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipses'} variant={'body2'} fontSize={'1.8rem'}>
              Excelente! chegou sua hora de receber um cartão
            </Typography>
          </Grid>

          <Grid container justifyContent={'center'} height={theme.spacing(8)}>
            <LinearStepper activeStep={3} />
          </Grid>

          <Grid
            container
            bgcolor={'transparent'}
            justifyContent={'center'}
            style={{
              backgroundColor: theme.palette.background.paper,
              backgroundImage: `url(${HomePicture})`,
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
            }}>
            <Grid
              container
              direction={'row'}
              xs={6}
              minHeight={theme.spacing(40)}
              borderRadius={theme.spacing(2)}
              padding={theme.spacing(4)}
              marginTop={theme.spacing(2)}
              marginBottom={theme.spacing(2)}
              bgcolor={theme.palette.background.default}>
              <Grid container justifyContent={'center'}>
                <Grid container justifyContent={'center'} alignContent={'center'} gap={2} sm={5}>
                  <Grid item>
                    <Typography overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipses'} variant={'body2'} fontSize={'1.6rem'}>
                      Receber meu cartão
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography align={'inherit'} variant={'body1'} fontSize={'1.3rem'}>
                      Ótimo agora você esta apto a receber o seu cartão
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item display={'flex'} justifyContent={'center'} height={theme.spacing(25)} sm={2}>
                  <Divider
                    variant={'fullWidth'}
                    orientation={'vertical'}
                    style={{
                      border: '1px solid gray',
                    }}
                  />
                </Grid>
                <Grid item display={'flex'} justifyContent={'center'} sm={5}>
                  <img src={Planet} />
                </Grid>
              </Grid>
              <Grid container justifyContent={'center'} alignContent={'center'}>
                <Grid item>
                  <Button
                    size={'large'}
                    variant={'contained'}
                    href="/cadastrar-endereco"
                    LinkComponent={Link}
                    sx={{
                      color: 'white',
                      textTransform: 'capitalize',
                      fontSize: '1rem',
                    }}>
                    Receber Cartão
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignContent={'end'}>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
