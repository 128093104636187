import { Grid, Link, useTheme, Typography, Divider } from '@mui/material';
import footerImageBg from '../../../assets/images/imagem-rodape-fundo.jpg';
import { EventTrackingService } from '../../services/ga/EventTrackingService';

export const FooterLandingPage = () => {
  const theme = useTheme();

  const gaCategory = 'footer-landing-page';

  return (
    <Grid container minHeight="40vh" color="white" sx={{ backgroundImage: `url(${footerImageBg})` }}>
      <Grid
        container
        sx={{
          justifyContent: {
            xs: 'center',
            md: 'start',
          },
          pt: {
            xs: theme.spacing(6),
            sm: theme.spacing(8),
            md: theme.spacing(10),
          },
          pl: {
            xs: theme.spacing(6),
            sm: theme.spacing(8),
            md: theme.spacing(16),
          },
        }}>
        <Grid item xs={5} sm={3}>
          <Grid item pb={2}>
            <Link
              href="/sobre"
              color="inherit"
              variant="body1"
              underline="none"
              onClick={() =>
                EventTrackingService.track({
                  category: gaCategory,
                  action: 'sobre-clique',
                  label: 'redirecionamento',
                })
              }>
              Sobre
            </Link>
          </Grid>
          <Grid item pb={2}>
            <Link
              href="/fale-conosco"
              color="inherit"
              variant="body1"
              underline="none"
              onClick={() =>
                EventTrackingService.track({
                  category: gaCategory,
                  action: 'contato-clique',
                  label: 'redirecionamento',
                })
              }>
              Contato
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={5} sm={3}>
          <Grid item pb={2}>
            <Link
              href="https://www.instagram.com/mandeumcartao/"
              target="_blank"
              color="inherit"
              variant="body1"
              underline="none"
              onClick={() =>
                EventTrackingService.track({
                  category: gaCategory,
                  action: 'rede-social-clique',
                  label: 'redirecionamento',
                })
              }>
              Rede Social
            </Link>
          </Grid>
          <Grid item pb={2}>
            <Link
              href="/time"
              color="inherit"
              variant="body1"
              underline="none"
              onClick={() =>
                EventTrackingService.track({
                  category: gaCategory,
                  action: 'time-clique',
                  label: 'redirecionamento',
                })
              }>
              Time
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignContent="end" pb={1}>
        <Link href="/" underline="none" color="inherit">
          <Typography>Mande um Cartão &reg; {new Date().getFullYear()}</Typography>
        </Link>
      </Grid>
      <Grid container justifyContent="center" alignContent="end" maxHeight={30} gap={3}>
        <Link
          href="/termos-de-uso"
          color="#d3d3d3"
          variant="body1"
          underline="none"
          onClick={() =>
            EventTrackingService.track({
              category: gaCategory,
              action: 'termos-de-uso-clique',
              label: 'redirecionamento',
            })
          }>
          Termos de Uso
        </Link>
        <Divider orientation="vertical" flexItem sx={{ border: '1px solid #d3d3d3' }} />
        <Link
          href="/politica-de-privacidade"
          color="#d3d3d3"
          variant="body1"
          underline="none"
          onClick={() =>
            EventTrackingService.track({
              category: gaCategory,
              action: 'politica-de-privacidade-clique',
              label: 'redirecionamento',
            })
          }>
          Política de Privacidade
        </Link>
      </Grid>
    </Grid>
  );
};
