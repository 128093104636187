import * as React from 'react';
import { useTheme, AppBar, Toolbar, Grid, Button, Menu, MenuItem, Avatar, Divider, Typography } from '@mui/material';

import UserProfile from '../../../assets/images/imagem-padrao-usuario.png';

export const HeaderHome = () => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid container height={theme.spacing(8)}>
      <AppBar color={'primary'} position={'static'} style={{ boxShadow: 'none' }}>
        <Toolbar>
          <Grid container alignContent={'center'} justifyContent={'end'} height={theme.spacing(5)} sm={12}>
            <Button
              id={'trigger-menu-button'}
              aria-controls={open ? 'actived-menu' : undefined}
              aria-haspopup={'true'}
              aria-expanded={open ? 'true' : undefined}
              onClick={handleMenuClick}>
              <Avatar alt={'O mais bonito'} src={'/logo192.png'} />
            </Button>
            <Menu
              id={'actived-menu'}
              aria-labelledby={'trigger-menu-button'}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}>
              <Grid
                container
                direction={'column'}
                justifyContent={'space-between'}
                marginTop={theme.spacing(2)}
                style={{
                  width: 300,
                  height: 300,
                  borderRadius: 50,
                }}>
                <Grid container flexDirection={'column'} alignContent={'center'} justifyContent={'center'} gap={1}>
                  <Grid item>
                    <img
                      src={UserProfile}
                      style={{
                        width: '6rem',
                        height: '6rem',
                      }}
                    />
                  </Grid>
                  <Grid item display={'flex'} justifyContent={'center'}>
                    <Typography variant={'body2'} fontSize={'1.2rem'}>
                      Victoria
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={6}>
                  <Divider />
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <Divider />
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <Divider />
                  <MenuItem onClick={handleClose}>Logout</MenuItem>
                  <Divider />
                </Grid>
              </Grid>
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
    </Grid>
  );
};
