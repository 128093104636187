import { useTheme, Grid, Typography, Divider, Accordion, AccordionSummary, AccordionDetails, List, ListItem, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { HeaderLandingPage, FooterLandingPage } from '../../shared/components';

export const TermosDeUsoPage = () => {
  const theme = useTheme();

  return (
    <>
      <HeaderLandingPage />
      <Grid
        container
        sx={{
          mt: {
            xs: theme.spacing(8),
          },
          p: {
            xs: theme.spacing(2),
            sm: theme.spacing(8),
            md: theme.spacing(10),
            lg: theme.spacing(14),
          },
        }}>
        <Grid container justifyContent="center">
          <Grid container direction="column" alignItems="center" gap={theme.spacing(5)}>
            <Typography variant="h3" fontWeight="500" paragraph>
              Termos de Uso
            </Typography>
            <Divider sx={{ border: '1px solid gray', width: '5%' }} />
            <Typography variant="h5" fontWeight="500" paragraph>
              Data de atualização: 27 de Outubro de 2023
            </Typography>
          </Grid>
          <Grid
            container
            bgcolor="#F6F5F5"
            borderRadius={4}
            direction="column"
            sx={{
              pt: {
                xs: theme.spacing(4),
                sm: theme.spacing(10),
              },
              pb: {
                xs: theme.spacing(4),
                sm: theme.spacing(10),
              },
              pr: {
                xs: theme.spacing(2),
                sm: theme.spacing(8),
              },
              pl: {
                xs: theme.spacing(2),
                sm: theme.spacing(8),
              },
            }}>
            <Typography paragraph>Olá, usuário.</Typography>
            <Typography paragraph>
              Por meio deste documento são estabelecidos os termos e as condições gerais para a utilização da Plataforma Mande um Cartão por Você, que é nosso
              usuário.
            </Typography>
            <Typography paragraph>
              Nosso maior objetivo é conectar as pessoas utilizando um antigo mais incrível meio de comunicação: o envio de cartas via correio. Aqui,
              diariamente comprovamos que existem cartões que valem tanto quanto ou até mais que um abraço.
            </Typography>
            <Typography paragraph>Chega mais e vem conferir os detalhes do termos de uso! :)</Typography>

            <Typography variant="h5" fontWeight="500" paragraph>
              Sua Responsabilidade
            </Typography>
            <Typography paragraph>
              Esperamos de você uma comunicação respeitosa e não agressiva com os outros usuários e o próprio time do Mande um Cartão, sempre zelando pela ética
              e integridade. <b>Tópico 3</b>
            </Typography>
            <Typography paragraph>
              Lembramos que não toleramos no Mande um Cartão qualquer forma de preconceito, assédio, bullying e incitação à violência. As condutas que
              envolverem homofobia, racismo, intolerância religiosa e/ou política, machismo, capacitismo ou qualquer ação que tenha por objetivo diminuir e/ou
              ofender alguém estão sujeitas à suspensão ou cancelamento da sua Conta de Usuário em nossa Plataforma Mande um Cartão, sem aviso prévio e sem
              prejuízo da adoção de medidas judiciais cabíveis. <b>Tópico 6</b>
            </Typography>

            <Typography variant="h5" fontWeight="500" paragraph>
              Seu Direito
            </Typography>
            <Typography paragraph>Ao criar uma conta no Mande um Cartão, nós poderemos coletar algumas informações importantes sobre você.</Typography>
            <Typography paragraph>
              Nós sinalizamos para que você fique tranquilo, pois suas informações podem ser resgastadas por você assim como prevista pelo LGPD.
            </Typography>
            <Typography paragraph>
              Nós mostramos como respeitamos a sua privacidade em nossa missão de proporcionar um serviço cada vez melhor! <b>Tópico 5</b>
            </Typography>
            <Typography paragraph>
              Para saber mais sobre como o Mande seu Cartão obtém, armazena, utiliza, compartilha e protege suas informações, confira a nossa Declaração de
              Privacidade dos Usuários.
            </Typography>

            {/*1st Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic1-content" id="topic1-header">
                <Typography variant="h6">1. Quem pode usar o Mande um Cartão?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Os serviços prestados pela Plataforma Mande um Cartão estão disponíveis para maiores de 18 anos.</Typography>
              </AccordionDetails>
            </Accordion>

            {/*2nd Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic1-content" id="topic1-header">
                <Typography variant="h6">2. Sobre sua conta de Usuário</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Ao criar uma conta no Mande seu Cartão (a “Conta de Usuário”), nós poderemos coletar algumas informações importantes sobre Você.
                </Typography>
                <Typography paragraph>
                  A sua Conta de Usuário pode ser criada atráves de um link único enviado via e-mail ou rede social, ou pela lista de espera disponibilizada na
                  Plataforma Mande um Cartão.
                </Typography>
                <Typography paragraph>Importante deixar claro que você poderá manter apenas 1 (uma) Conta de Usuário ativa.</Typography>
                <Typography paragraph>
                  Em algumas hipóteses, incluindo, mas não se limitando a caso de duplicidade de cadastros, o Mande seu Cartão poderá desativar sua Conta de
                  Usuário, sem um aviso prévio observadas as hipóteses da cláusula 4.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* 3rd Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic1-content" id="topic1-header">
                <Typography variant="h6">3. São suas responsabilidades</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  <ListItem>
                    1. Manter a confidencialidade e a segurança dos dispositivos utilizados para acesso à sua Conta de Usuário e/ou Plataforma Mande um Cartão,
                    incluindo seu usuário e senha - essas informações são pessoais e intransferíveis!
                  </ListItem>
                  <ListItem>2. Todas as atividades ou ações que ocorram ou sejam tomadas em sua Conta de Usuário;</ListItem>
                  <ListItem>
                    3. Manter uma comunicação respeitosa e não agressiva com os Estabelecimentos, Entregadores e o próprio time do Mande um Cartão, sempre
                    zelando pela ética e integridade;
                  </ListItem>
                  <ListItem>
                    4. Prestar as informações exigidas com veracidade e exatidão, assumindo a responsabilidade pelo que é fornecido por Você durante o uso da
                    Plataforma Mande um Cartão.
                  </ListItem>
                </List>
                <Typography paragraph>
                  A utilização da Plataforma Mande um Cartão pressupõe a sua aceitação, compreensão e vinculação a estes Termos. Assim, sempre que você fizer
                  uso de quaisquer serviços oferecidos pelo Mande um Cartão, estará ciente da necessidade de respeitar e seguir todas as regras e obrigações
                  disponíveis nestes Termos.
                </Typography>
                <Typography paragraph>
                  Caso você não concorde com alguma disposição destes Termos, é recomendada a suspensão imediata do uso da Plataforma Mande um Cartão e o
                  cancelamento da sua Conta de Usuário na referida plataforma. Você pode realizar essa solicitação diretamente na Plataforma Mande um Cartão,
                  via menu de configurações no perfil do seu usuário.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* 4th Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic1-content" id="topic1-header">
                <Typography variant="h6">4. Informações sobre suspensão e cancelamento da sua Conta de Usuário</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  O Mande seu Cartão poderá impedir seu cadastramento como Usuário, suspender ou cancelar sua Conta de Usuário, caso:
                </Typography>
                <List
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  <ListItem>1. verifique qualquer descumprimento, ação e/ou omissão cuja intenção seja burlar o disposto nestes Termos;</ListItem>
                  <ListItem>2. detecte qualquer anomalia na Conta de Usuário;</ListItem>
                  <ListItem>3. seja encontrada qualquer informação fornecida por você de maneira incorreta, inverídica ou duvidosa;</ListItem>
                  <ListItem>
                    4. você tenha alguma objeção a estes Termos ou à alguma atualização posterior, ou seja, caso você não concorde integralmente com o conteúdo
                    destes Termos, a Plataforma Mande seu Cartão não poderá ser utilizada;
                  </ListItem>
                  <ListItem>5. haja determinação judicial e/ou descumprimento de quaisquer leis.</ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            {/* 5th Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic1-content" id="topic1-header">
                <Typography variant="h6">5. Declaração de Privacidade de Usuários</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  A proteção dos seus dados é muito importante para o Mande seu Cartão. Respeitamos a sua privacidade em nossa missão de proporcionar umserviço
                  cada vez melhor! Para saber mais sobre como o Mande seu Cartão obtém, armazena, utiliza, compartilha e protege suas informações, confira a
                  nossa Declaração de Privacidade para Usuários publicada em nosso <Link href="/politica-de-privacidade">site</Link>.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* 6th Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic1-content" id="topic1-header">
                <Typography variant="h6">6. Propriedade Intelectual</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Tudo que foi produzido pelo Mande um Cartão e é utilizado em nossa Plataforma Mande um Cartão é protegido por direito de propriedade
                  intelectual. Portanto, as informações aqui disponibilizadas são para o seu uso pessoal e aprimoramento da sua navegação. Todos os direitos de
                  propriedade intelectual ou industrial relativos à Plataforma Mande um Cartão, a marca Mande um Cartão e/ou aos Softwares pertencem única e
                  exclusivamente ao Mande um Cartão. Em nenhuma hipótese esses Termos implicam transferência, no todo ou em parte, de qualquer direito de
                  propriedade intelectual ou industrial do Mande um Cartão para você, usuário.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* 7th Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic1-content" id="topic1-header">
                <Typography variant="h6">7. O Mande seu Cartão não tolera</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  O Mande seu Cartão é para todas as pessoas e temos como compromisso a diversidade e inclusão. Assim, não toleramos qualquer forma de
                  preconceito, assédio, bullying e incitação à violência. As condutas que envolverem homofobia, racismo, intolerância religiosa e/ou política,
                  machismo, capacitismo ou qualquer ação que tenha por objetivo diminuir e/ou ofender alguém, estão sujeitas à suspensão ou cancelamento da
                  Conta de Usuário em nossa Plataforma Mande seu Cartão, sem aviso prévio e sem prejuízo da adoção de medidas judiciais cabíveis.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
      <FooterLandingPage />
    </>
  );
};
