import { useTheme, Grid, Typography, Button, Link } from '@mui/material';

import HomePicture from '../../assets/images/imagem-homem-e-envelope.png';

import { HeaderHome, Footer } from '../../shared/components';

export const SortearAlguem = () => {
  const theme = useTheme();

  const info = {
    name: 'Rayane',
    instagram: '@Rayane225',
    cep: '12156188',
    endereço: 'Rua Albert Camillo',
    num: '254',
    bairro: 'Rúmeril',
    cidade: 'Ravinsk',
    estadoUF: 'Amank',
    complemento: 'Casa',
  };

  return (
    <>
      <Grid
        container
        overflow={'hidden'}
        style={{
          minHeight: '100vh',
        }}>
        <Grid container alignContent={'space-between'}>
          <HeaderHome />

          <Grid container justifyContent={'center'}>
            <Typography overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipses'} variant={'body2'} fontSize={'2rem'}>
              Ebaa!!
            </Typography>
          </Grid>

          <Grid
            container
            justifyContent={'center'}
            style={{
              backgroundColor: theme.palette.background.paper,
              backgroundImage: `url(${HomePicture})`,
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
            }}>
            <Grid
              container
              direction={'row'}
              xs={12}
              sm={6}
              justifyContent={'center'}
              minHeight={theme.spacing(50)}
              borderRadius={theme.spacing(2)}
              padding={theme.spacing(1)}
              marginTop={theme.spacing(2)}
              marginBottom={theme.spacing(10)}
              bgcolor={theme.palette.background.default}>
              <Grid container justifyContent={'center'} marginTop={theme.spacing(2)}>
                <Typography overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipses'} variant={'body2'} fontSize={'2rem'}>
                  Você sorteou o(a):
                </Typography>
                <Grid container justifyContent={'center'} gap={4}>
                  <Grid container width={'14rem'} flexDirection={'column'} justifyContent={'space-between'}>
                    <Grid item height={'4rem'}>
                      <Typography variant={'body2'} fontSize={'1.2rem'}>
                        Nome
                      </Typography>
                      <Typography variant={'body1'} fontSize={'1rem'}>
                        {info.name}
                      </Typography>
                    </Grid>
                    <Grid item height={'4rem'}>
                      <Typography variant={'body2'} fontSize={'1.2rem'}>
                        Cep
                      </Typography>
                      <Typography variant={'body1'} fontSize={'1rem'}>
                        {info.cep}
                      </Typography>
                    </Grid>
                    <Grid item height={'4rem'}>
                      <Typography variant={'body2'} fontSize={'1.2rem'}>
                        Bairro
                      </Typography>
                      <Typography variant={'body1'} fontSize={'1rem'}>
                        {info.bairro}
                      </Typography>
                    </Grid>
                    <Grid item height={'4rem'}>
                      <Typography variant={'body2'} fontSize={'1.2rem'}>
                        Estado /UF
                      </Typography>
                      <Typography variant={'body1'} fontSize={'1rem'}>
                        {info.estadoUF}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container width={'14rem'} flexDirection={'column'} justifyContent={'space-between'}>
                    <Grid item height={'4rem'}>
                      <Typography variant={'body2'} fontSize={'1.2rem'}>
                        Instagram
                      </Typography>
                      <Typography variant={'body1'} fontSize={'1rem'}>
                        <Link href="https://www.instagram.com/">{info.instagram}</Link>
                      </Typography>
                    </Grid>
                    <Grid item display={'flex'} flexDirection={'row'} gap={2} height={'4rem'}>
                      <Grid item height={'4rem'} display={'flex'} flexDirection={'column'}>
                        <Typography variant={'body2'} fontSize={'1.2rem'}>
                          Endereço
                        </Typography>
                        <Typography variant={'body1'} fontSize={'1rem'}>
                          {info.endereço}
                        </Typography>
                      </Grid>
                      <Grid item display={'flex'} flexDirection={'column'} height={'4rem'}>
                        <Typography variant={'body2'} fontSize={'1.2rem'}>
                          N°
                        </Typography>
                        <Typography variant={'body1'} fontSize={'1rem'}>
                          {info.num}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item height={'4rem'}>
                      <Typography variant={'body2'} fontSize={'1.2rem'}>
                        Cidade
                      </Typography>
                      <Typography variant={'body1'} fontSize={'1rem'}>
                        {info.cidade}
                      </Typography>
                    </Grid>
                    <Grid item height={'4rem'}>
                      <Typography variant={'body2'} fontSize={'1.2rem'}>
                        Complemento
                      </Typography>
                      <Typography variant={'body1'} fontSize={'1rem'}>
                        {info.complemento}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent={'center'} alignItems={'center'} gap={2}>
                <Grid item>
                  <Button
                    size={'large'}
                    variant={'contained'}
                    href={'/envio-do-cartao'}
                    LinkComponent={Link}
                    sx={{
                      color: 'white',
                      textTransform: 'capitalize',
                      fontSize: '1rem',
                    }}>
                    Confirmar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size={'large'}
                    variant={'outlined'}
                    href={'/sortear'}
                    LinkComponent={Link}
                    sx={{
                      color: 'primary',
                      textTransform: 'capitalize',
                      fontSize: '1rem',
                    }}>
                    Sortear novamente
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignItems={'end'}>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
