import { Grid } from '@mui/material';

import EmailIcon from '../../../assets/images/icone-email.png';

export const IconEmail = () => {
  return (
    <Grid item display={'flex'} alignItems={'center'} justifyContent={'initial'}>
      <img src={EmailIcon} style={{ width: '30px' }} alt="email-icone" />
    </Grid>
  );
};
