import { Grid } from '@mui/material';

import DocumentIcon from '../../../assets/images/icone-documento.png';

export const IconDocument = () => {
  return (
    <Grid item display={'flex'} alignItems={'center'} justifyContent={'initial'}>
      <img src={DocumentIcon} style={{ width: '30px' }} alt="documento-icone" />
    </Grid>
  );
};
