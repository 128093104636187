import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import * as ReactGA from 'react-ga';

import { useDrawerContext } from '../shared/contexts';
import {
  Home,
  SingIn,
  SingUp,
  LandingPage,
  RecoveryPassword,
  ResetPassword,
  SortearAlguem,
  EnviarCartao,
  MarcarEnvio,
  ReceberCartao,
  CadastrarEndereco,
  CompartilharConclusao,
  TermosDeUsoPage,
  PoliticaDePrivacidadePage,
  TeamPage,
  AboutPage,
  ContactUsPage,
} from '../pages';

export const AppRoutes = () => {
  const { setDrawerOptions } = useDrawerContext();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    setDrawerOptions([
      {
        icon: 'home',
        path: '/home',
        label: 'Home',
      },
      {
        icon: 'library_books',
        path: '/books',
        label: 'Books',
      },
      {
        icon: 'people',
        path: '/customers',
        label: 'Customers',
      },
    ]);
  }, []);

  return (
    <Routes>
      <Route path="/entrar" element={<SingIn />} />
      <Route path="/criar-conta" element={<SingUp />} />
      <Route path="/recuperar-senha" element={<RecoveryPassword />} />
      <Route path="/trocar-senha" element={<ResetPassword />} />

      <Route path="/inicio" element={<Home />} />
      <Route path="/sortear" element={<SortearAlguem />} />
      <Route path="/enviar-cartao" element={<EnviarCartao />} />
      <Route path="/marcar-como-enviado" element={<MarcarEnvio />} />
      <Route path="/receber-cartao" element={<ReceberCartao />} />
      <Route path="/cadastrar-endereco" element={<CadastrarEndereco />} />
      <Route path="/compartilhar" element={<CompartilharConclusao />} />

      <Route path="/time" element={<TeamPage />} />
      <Route path="/sobre" element={<AboutPage />} />
      <Route path="/fale-conosco" element={<ContactUsPage />} />
      <Route path="/termos-de-uso" element={<TermosDeUsoPage />} />
      <Route path="/politica-de-privacidade" element={<PoliticaDePrivacidadePage />} />

      <Route path="*" element={<LandingPage />} />
    </Routes>
  );
};
