import { Grid } from '@mui/material';
import iconCycle from '../../../assets/images/icone-ciclo.png';

export const IconCycle = () => {
  return (
    <Grid item>
      <img src={iconCycle} alt="icone-circulo-repeticao" width="150px" />
    </Grid>
  );
};
