import { useTheme, Grid, Typography, Button, Link } from '@mui/material';

import HomePicture from '../../assets/images/imagem-homem-e-envelope.png';

import { HeaderHome, LinearStepper, Footer } from '../../shared/components';

export const EnviarCartao = () => {
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        overflow={'hidden'}
        style={{
          minHeight: '100vh',
        }}>
        <Grid container alignContent={'space-between'}>
          <HeaderHome />

          <Grid container justifyContent={'center'}>
            <Typography overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipses'} variant={'body2'} fontSize={'1.8rem'}>
              Ótimo! Agora basta seguir o passo a passo de envio.
            </Typography>
          </Grid>

          <Grid container justifyContent={'center'} height={theme.spacing(8)}>
            <LinearStepper activeStep={1} />
          </Grid>

          <Grid
            container
            bgcolor={'transparent'}
            justifyContent={'center'}
            style={{
              backgroundColor: theme.palette.background.paper,
              backgroundImage: `url(${HomePicture})`,
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
            }}>
            <Grid
              container
              direction={'row'}
              xs={6}
              minHeight={theme.spacing(40)}
              borderRadius={theme.spacing(2)}
              padding={theme.spacing(4)}
              marginTop={theme.spacing(2)}
              marginBottom={theme.spacing(2)}
              bgcolor={theme.palette.background.default}>
              <Grid container justifyContent={'center'}>
                <Typography overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipses'} variant={'body2'} fontSize={'1.6rem'}>
                  Passo a passo de envio:
                </Typography>
              </Grid>
              <Grid container justifyContent={'center'}>
                <Grid item sm={8}>
                  <Typography align={'inherit'} variant={'body1'} fontSize={'1.3rem'}>
                    * Imprima o modelo do envelope
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Typography align={'inherit'} variant={'body1'} fontSize={'1.3rem'}>
                    * Escreva a carta
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Typography align={'inherit'} variant={'body1'} fontSize={'1.3rem'}>
                    * Despache no agência de correios mais próxima
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={'center'} alignContent={'center'}>
                <Grid item>
                  <Button
                    size={'large'}
                    variant={'contained'}
                    href={'/marcar-envio'}
                    LinkComponent={Link}
                    sx={{
                      color: 'white',
                      textTransform: 'capitalize',
                      fontSize: '1rem',
                    }}>
                    Confirmar envio
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignContent={'end'}>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
