import { Grid } from '@mui/material';
import GitHubIcon from '../../../assets/images/icone-github.png';

export const IconGitHub = () => {
  return (
    <Grid item display={'flex'} alignItems={'center'} justifyContent={'initial'}>
      <img src={GitHubIcon} style={{ width: '30px' }} alt="github-icone" />
    </Grid>
  );
};
