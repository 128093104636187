import { useTheme, Grid, Typography, Divider, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { HeaderLandingPage, FooterLandingPage } from '../../shared/components';

export const PoliticaDePrivacidadePage = () => {
  const theme = useTheme();

  return (
    <>
      <HeaderLandingPage />
      <Grid
        container
        sx={{
          mt: {
            xs: theme.spacing(8),
          },
          p: {
            xs: theme.spacing(2),
            sm: theme.spacing(8),
            md: theme.spacing(10),
            lg: theme.spacing(14),
          },
        }}>
        <Grid container justifyContent="center">
          <Grid container direction="column" alignItems="center" gap={theme.spacing(5)}>
            <Typography variant="h3" fontWeight="500" paragraph>
              Política de privacidade
            </Typography>
            <Divider sx={{ border: '1px solid gray', width: '5%' }} />
            <Typography variant="h5" fontWeight="500" paragraph>
              Data de atualização: 27 de Outubro de 2023
            </Typography>
          </Grid>
          <Grid
            container
            bgcolor="#F6F5F5"
            borderRadius={4}
            direction="column"
            sx={{
              pt: {
                xs: theme.spacing(4),
                sm: theme.spacing(10),
              },
              pb: {
                xs: theme.spacing(4),
                sm: theme.spacing(10),
              },
              pr: {
                xs: theme.spacing(2),
                sm: theme.spacing(8),
              },
              pl: {
                xs: theme.spacing(2),
                sm: theme.spacing(8),
              },
            }}>
            <Typography variant="h5" fontWeight="500" paragraph>
              Seus dados são seus
            </Typography>
            <Typography paragraph>
              A proteção dos seus dados é muito importante para o Mande um Cartão. Respeitamos a sua privacidade em nossa missão de proporcionar um serviço cada
              vez melhor!
            </Typography>
            <Typography paragraph>
              Essa Declaração de Privacidade descreve como obtemos, armazenamos, utilizamos, compartilhamos e protegemos as suas informações.
            </Typography>
            <Typography paragraph>
              Recomendamos que você conheça as nossas práticas como um todo, mas aqui estão alguns pontos importantes para que você entenda como seus dados são
              tratados pelo Mande um Cartão:
            </Typography>
            <List
              sx={{
                pt: 0,
                fontFamily: 'Montserrat, sans-serif',
              }}>
              <ListItem>
                <Typography>
                  1. Os dados que você cadastra em nossas plataformas &nbsp;<b>são tratados de forma segura</b>.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  2. Limitamos o tratamento dos dados para as suas devidas finalidades e em &nbsp;<b>conformidade com a lei</b>.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>3. Você pode alterar suas preferências de comunicação no próprio site.</Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  4. Você poderá tirar suas dúvidas e solicitar informações a qualquer momento através da aba Ajuda em nossas plataformas.
                </Typography>
              </ListItem>
            </List>

            <Grid
              container
              justifyContent="center"
              sx={{
                pt: 7,
                pb: 7,
              }}>
              <Typography variant="h5" fontWeight="500" paragraph>
                Para facilitar seu acesso às informações desta Declaração, é só clicar no capítulo desejado:
              </Typography>
            </Grid>

            {/*1st Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic1-content" id="topic1-header">
                <Typography variant="h6">1. A quem esta Declaração se aplica?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Esta <b>Declaração de Privacidade</b>, também chamada de ”Declaração”, <b>se aplica às pessoas que</b>:
                </Typography>
                <List
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  <ListItem> • acessarem,</ListItem>
                  <ListItem> • se cadastrarem,</ListItem>
                  <ListItem> • ou de qualquer outra forma utilizarem os produtos do Mande um Cartão</ListItem>
                </List>
                <Typography>Nosso site será denominado com ”Plataforma”.</Typography>
              </AccordionDetails>
            </Accordion>

            {/*2nd Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic2-content" id="topic2-header">
                <Typography variant="h6">2. O que é tratamento de dados pessoais?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  O tratamento de dados pessoais (ou tratar dados) significa <b>qualquer operação realizada com esses dados</b>. Por exemplo:
                </Typography>
                <List
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  <ListItem> • Coleta</ListItem>
                  <ListItem> • Utilização</ListItem>
                  <ListItem> • Acesso</ListItem>
                  <ListItem> • Processamento</ListItem>
                  <ListItem> • Armazenamento</ListItem>
                  <ListItem> • Eliminação</ListItem>
                  <ListItem> • Comunicação</ListItem>
                  <ListItem> • Transferência</ListItem>
                </List>
                <Typography>
                  O dado pessoal é uma informação relacionada a uma pessoa humana identificada ou identificável. O dado relativo a você, cliente do Mande um
                  Cartão, <b>é um dado pessoal</b>.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* 3rd Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic3-content" id="topic3-header">
                <Typography variant="h6">3. São suas responsabilidades</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Quando você acessar ou se cadastrar em nossa Plataforma, o Mande um Cartão exerce o papel de controlador do tratamento de seus dados pessoais,
                  atuando conforme a legislação aplicável e conforme descrito na presente Declaração.
                </Typography>
                <List
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  <ListItem> • O controlador é a pessoa física ou jurídica que decide como será realizado o tratamento de dados pessoais.</ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            {/* 4th Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic4-content" id="topic4-header">
                <Typography variant="h6">4. Quais dados são coletados?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Ao criar uma conta no Mande um Cartão, nós poderemos coletar informações importantes sobre você. Abaixo, detalhamos quais são essas
                  informações:
                </Typography>
                <List
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  {/* 1st item */}
                  <ListItem>
                    <ListItemText>
                      <b>1. Dados de seu perfil:</b>
                      <List
                        sx={{
                          p: 0,
                          fontFamily: 'Montserrat, sans-serif',
                        }}>
                        <ListItem>
                          <ListItemText>a. seu nome, e-mail, endereço, rede social e preferências.</ListItemText>
                        </ListItem>
                      </List>
                    </ListItemText>
                  </ListItem>

                  {/* 2nd item */}
                  <ListItem>
                    <ListItemText>
                      <b>2. Dados gerados durante a utilização dos serviços:</b>
                      <List
                        sx={{
                          p: 0,
                          fontFamily: 'Montserrat, sans-serif',
                        }}>
                        <ListItem>
                          <ListItemText>
                            endereços de IP, sistema operacional, informações sobre data e horário, dados sobre a sequência de cliques e fabricante do
                            dispostivo.
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ListItemText>
                  </ListItem>

                  {/* 3rd item */}
                  <ListItem>
                    <ListItemText>
                      <b>3. Dados transacionais e dados sobre sua utilização:</b>
                      <List
                        sx={{
                          p: 0,
                          fontFamily: 'Montserrat, sans-serif',
                        }}>
                        <ListItem>
                          <ListItemText>a. data e horário de acessos, visualizações, data e horário do sorteio e comprovante envio do cartão.</ListItemText>
                        </ListItem>
                      </List>
                    </ListItemText>
                  </ListItem>

                  {/* 4th item */}
                  <ListItem>
                    <ListItemText>
                      <b>4. Dados não pessoais:</b>
                      <List
                        sx={{
                          p: 0,
                          fontFamily: 'Montserrat, sans-serif',
                        }}>
                        <ListItem>
                          <ListItemText>
                            a. nós também podemos obter informações não pessoais, ou seja, dados que não permitem a associação direta com qualquer pessoa
                            especificamente. É um exemplo de dado não pessoal o agrupamento de sorteios por região.
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ListItemText>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            {/* 5th Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic5-content" id="topic5-header">
                <Typography variant="h6">5. Por que coletamos os seus dados?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>Saiba quais são as finalidades de tratamento dos seus dados pessoais relacionadas aos serviços da Plataforma.</Typography>
                <List
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  {/* 1st item */}
                  <ListItem>
                    <ListItemText>
                      <b>1. Para prover nossos serviços</b>
                      <List
                        sx={{
                          fontFamily: 'Montserrat, sans-serif',
                        }}>
                        <ListItem>
                          <ListItemText>a. seu nome, e-mail, endereço, rede social e preferências.</ListItemText>
                        </ListItem>
                      </List>
                    </ListItemText>
                  </ListItem>

                  {/* 2nd item */}
                  <ListItem>
                    <ListItemText>
                      <b>2. Para te enviar comunicações importantes</b>
                      <List
                        sx={{
                          fontFamily: 'Montserrat, sans-serif',
                        }}>
                        <ListItem>
                          <ListItemText>
                            endereços de IP, sistema operacional, informações sobre data e horário, dados sobre a sequência de cliques e fabricante do
                            dispostivo.
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ListItemText>
                  </ListItem>

                  {/* 3rd item */}
                  <ListItem>
                    <ListItemText>
                      <b>3. Para te oferecer suporte</b>
                      <List
                        sx={{
                          pb: 0,
                          fontFamily: 'Montserrat, sans-serif',
                        }}>
                        <ListItem>
                          <ListItemText>
                            a. Também teremos acesso às suas informações quando você solicitar suporte em relação a pedidos ou entregas por meio da aba “Ajuda”
                            na Plataforma.
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>Neste caso, poderemos utilizar seus dados cadastrais para:</ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>
                            <List
                              sx={{
                                pb: 0,
                                fontFamily: 'Montserrat, sans-serif',
                              }}>
                              <ListItem>
                                <ListItemText> • confirmar que a solicitação foi feita por você,</ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemText> • direcionar as suas dúvidas para o atendente de suporte,</ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemText> • investigar e direcionar seu problema,</ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemText> • monitorar e melhorar nossos procedimentos de suporte.</ListItemText>
                              </ListItem>
                            </List>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ListItemText>
                  </ListItem>

                  {/* 4th item */}
                  <ListItem>
                    <ListItemText>
                      <b>4. Para segurança e prevenção à fraude</b>
                      <List
                        sx={{
                          fontFamily: 'Montserrat, sans-serif',
                        }}>
                        <ListItem>
                          <ListItemText>
                            a. Nós também podemos utilizar seus dados pessoais para aprimorar nossos mecanismos de segurança. Dessa forma, podemos analisar e
                            solucionar problemas técnicos, bem como identificar e coibir fraudes na utilização do nosso serviço.
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ListItemText>
                  </ListItem>

                  {/* 5th item */}
                  <ListItem>
                    <ListItemText>
                      <b>5. Em caso de requisições legais e regulatórias</b>
                      <List
                        sx={{
                          fontFamily: 'Montserrat, sans-serif',
                        }}>
                        <ListItem>
                          <ListItemText>
                            a. Seus dados poderão ser utilizados para endereçarmos reivindicações jurídicas e regulatórias relacionadas à utilização dos nossos
                            serviços.
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ListItemText>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            {/* 6th Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic6-content" id="topic6-header">
                <Typography variant="h6">6. Como protegemos seus dados</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  O Mande um Cartão adota medidas de segurança, técnicas e administrativas&nbsp;
                  <b>para proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas</b>
                  &nbsp;de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.
                </Typography>

                <Typography paragraph>
                  Utilizamos os princípios estipulados por lei,&nbsp;
                  <b>respeitando a sua privacidade e protegendo seus dados em nossos</b>
                  &nbsp;processos internos como um todo.
                </Typography>

                <Typography paragraph>
                  Só tratamos os seus dados mediante &nbsp;<b>alto grau de segurança</b>&nbsp;, implementando as &nbsp;<b>melhores práticas</b>&nbsp; em uso na
                  indústria para a &nbsp;<b>proteção de dados</b>&nbsp;, tais como técnicas de criptografia, monitoramento e testes de segurança periódicos.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* 7th Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic7-content" id="topic7-header">
                <Typography variant="h6">7. Como os dados são armazenados?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>Saiba onde e por quanto tempo armazenamos os seus dados.</Typography>
                <List
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  {/* 1st item */}
                  <ListItem>
                    <ListItemText>
                      <b>1. Onde os dados são armazenados?</b>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <b>Os dados que coletamos de você são armazenados em serviços de nuvem confiáveis</b>, de parceiros que podem estar localizados no Brasil
                      ou em outros países que ofereçam serviço de armazenamento em nuvem confiáveis e usualmente utilizados por empresas de tecnologia, tais
                      como Estados Unidos da América (EUA) e em países da América Latina e da Europa.
                    </ListItemText>
                  </ListItem>

                  {/* 2nd item */}
                  <ListItem>
                    <ListItemText>
                      <b>2. Por quanto tempo os dados são armazenados?</b>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      O Mande seu Cartão armazena as suas informações durante o período necessário para as finalidades apresentadas nos <b>Termos Uso</b> e
                      nesta Declaração de Privacidade, respeitando o período de retenção de dados determinado pela legislação aplicável.
                    </ListItemText>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            {/* 8th Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic7-content" id="topic7-header">
                <Typography variant="h6">8. Como os dados são compartilhados?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>Saiba com quem o Mande um Cartão pode compartilhar os seus dados.</Typography>
                <List
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  {/* 1st item */}
                  <ListItem>
                    <ListItemText>
                      <b>1. Provedores de serviços</b>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Por razões de processamento de dados em nome do Mande um Cartão, poderemos compartilhar dados com terceiros prestadores de serviços. Nesse
                      caso, os dados serão tratados de <b>forma a proteger a sua privacidade</b>, tendo essas empresas o dever contratual de garantir proteção
                      compatível com a legislação aplicável e com os termos desta Declaração de Privacidade do Mande um Cartão.
                    </ListItemText>
                  </ListItem>

                  {/* 2nd item */}
                  <ListItem>
                    <ListItemText>
                      <b>2. Parceiros comerciais</b>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Podemos compartilhar seus dados com empresas com as quais o Mande um Cartão mantém um relacionamento de colaboração ou parceria, a fim de
                      realizar atividades financeiras ou comerciais que envolvem oferecer <b>promoções, produtos e serviços</b> dessas empresas em conjunto com
                      o Mande um Cartão.
                    </ListItemText>
                  </ListItem>

                  {/* 3rd item */}
                  <ListItem>
                    <ListItemText>
                      <b>3. Em alteração de controle societário do Mande um Cartão</b>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      No caso de operações societárias, como reestruturação, fusão ou venda de ativos do Mande um Cartão, do grupo econômico ou de parte dele,
                      seus dados poderão ser transferidos, desde que respeitados os termos da presente Declaração.
                    </ListItemText>
                  </ListItem>

                  {/* 4th item */}
                  <ListItem>
                    <ListItemText>
                      <b>4. Empresas de serviços de análise</b>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Para entregarmos publicidade e promoções adequadas para você, bem como <b>aperfeiçoar nossos serviços</b>, nós também podemos compartilhar
                      os seus dados anonimizados com empresas especializadas em marketing e análise de dados digitais que ofereçam&nbsp;
                      <b>nível deproteção de dados compatível</b> com esta Declaração de Privacidade.
                    </ListItemText>
                  </ListItem>

                  {/* 5th item */}
                  <ListItem>
                    <ListItemText>
                      <b>5. Autoridades públicas</b>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      O Mande um Cartão também poderá compartilhar suas informações com autoridades policiais ou judiciais e autoridades públicas competentes,
                      dentro e fora do país em que você reside, caso seja requerido pela legislação aplicável, por decisão judicial e por requisição de
                      autoridades, ou se necessário para responder a processos judiciais ou para participar em eventuais litígios ou disputas de qualquer
                      natureza.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Nessas situações, o Mande um Cartão tem o dever de cooperar com as autoridades competentes na medida do estipulado por lei.
                    </ListItemText>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            {/* 9th Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic7-content" id="topic7-header">
                <Typography variant="h6">9. Como utilizamos cookies e outras tecnologias?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  O Mande um Cartão pode utilizar, para uma variedade de funções, tecnologias como
                  <b>&nbsp;cookies, pixel tags, armazenamento local ou outros identificadores</b>, tanto de dispositivos móveis ou não, e/ou tecnologias
                  semelhantes (“cookies e outras tecnologias”), para uma variedade de funções. Essas tecnologias nos ajudam
                  <b>
                    &nbsp;a autenticar a sua conta, promover e aperfeiçoar os serviços do Mande um Cartão, personalizar a sua experiência e avaliar a eficácia
                    da nossa comunicação e publicidade.
                  </b>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* 10th Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic7-content" id="topic7-header">
                <Typography variant="h6">10. Quais são os seus direitos?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>Citamos, abaixo, os direitos de titular que você pode nos solicitar:</Typography>
                <List
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  <ListItem>
                    <ListItemText>
                      <b>1. Confirmar se o tratamento existe.</b>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <b>2. Solicitar acesso aos dados tratados.</b>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <b>3. Corrigir dados incompletos, inexatos ou desatualizados.</b>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <b>4. Ter os dados não essenciais anonimizados, bloqueados ou eliminados.</b>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <b>5. Saber com quais parceiros o Mande um Cartão compartilha seus dados.</b>
                    </ListItemText>
                  </ListItem>
                </List>
                <Typography paragraph>Saiba mais no item 11.</Typography>
              </AccordionDetails>
            </Accordion>

            {/* 11th Topic */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="topic7-content" id="topic7-header">
                <Typography variant="h6">11. Como exercer os seus direitos?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Para que você exerça <b>seus direitos</b> enquanto titular de dados pessoais, o Mande um Cartão disponibiliza meios específicos para as
                  requisições através da <b>aba “Ajuda”</b> em nossa Plataforma.
                </Typography>
                <Typography paragraph>
                  Você pode clicar no menu inferior “Perfil”, depois em “Ajuda” e, em seguida, “Conta” e encontrará respostas e direcionamentos específicos para
                  sua requisição.
                </Typography>
                <Typography paragraph>
                  Se você tiver algum questionamento ou dúvida com relação a esta Declaração de Privacidade do Mande um Cartão ou qualquer prática aqui
                  descrita, <b>você também poderá entrar em contato conosco através da nossa página de “Ajuda” na Plataforma.</b>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
      <FooterLandingPage />
    </>
  );
};
