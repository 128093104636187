import { useTheme, Grid } from '@mui/material';
import Logo from '../../../assets/images/logo.png';

export const LogoImage = () => {
  const theme = useTheme();

  const centerByBreakpoint = {
    [theme.breakpoints.between(0, 400)]: {
      display: 'grid',
      justifyContent: 'center',
    },
  } as const;

  return (
    <Grid item sx={centerByBreakpoint}>
      <img src={Logo} alt="mande-um-cartao-logo" width="210px" />
    </Grid>
  );
};
