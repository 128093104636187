import { useTheme, AppBar, Toolbar, Grid, Button, Link } from '@mui/material';
import { LogoImage } from '../';
import { EventTrackingService } from '../../services/ga/EventTrackingService';

export const HeaderLandingPage = () => {
  const theme = useTheme();

  const gaCategory = 'header-landing-page';

  const hiddenByBreakpoint = {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.between(0, 400)]: {
      display: 'none',
    },
  } as const;

  return (
    <AppBar color="inherit" position="fixed" sx={{ boxShadow: 'none' }}>
      <Toolbar>
        <Grid
          container
          sx={{
            padding: {
              xs: theme.spacing(2),
              sm: theme.spacing(4),
            },
          }}>
          <Grid item flexGrow={1}>
            <Link href="/" underline="none" color="inherit">
              <LogoImage />
            </Link>
          </Grid>
          <Grid item flexGrow={1} sx={hiddenByBreakpoint}>
            <Button
              color="primary"
              variant="contained"
              href={`${window.location.origin}/#participar`}
              onClick={() =>
                EventTrackingService.track({
                  category: gaCategory,
                  action: 'participe-clique',
                  label: 'redirecionamento',
                })
              }>
              PARTICIPE
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
