import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import './global.css';

import { App } from './App';

TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  dataLayerName: 'PageDataLayer',
});

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
