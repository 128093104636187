import { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTheme, Grid, Typography, Divider, Button, TextField, MenuItem, Link } from '@mui/material';
import { HeaderLandingPage, FooterLandingPage, IconDocument, IconEmail } from '../../shared/components';
import { ContactService, IContact } from '../../shared/services/api/contact/ContactService';
import { EventTrackingService } from '../../shared/services/ga/EventTrackingService';

export const ContactUsPage = () => {
  const theme = useTheme();

  const gaCategory = 'fale-conosco';

  const selectOptions = [
    {
      value: 'Relatar um problema',
      label: 'Relatar um problema',
    },
    {
      value: 'Tirar uma dúvida',
      label: 'Tirar uma dúvida',
    },
    {
      value: 'Outra',
      label: 'Outra',
    },
  ];

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const [reason, setReason] = useState('');
  const [reasonError, setReasonError] = useState(false);

  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(false);

  const [token, setToken] = useState('');
  const [reCaptchaError, setReCaptchaError] = useState(false);

  const { register, handleSubmit } = useForm<IContact>();

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setName(name);
    setNameError(false);
    if (name == '') {
      setNameError(true);
    }
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setEmail(email);
    setEmailError(false);

    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i;
    if (!regex.test(email)) {
      setEmailError(true);
    }
  };

  const onChangeReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reason = event.target.value;
    setReason(reason);
    setReasonError(false);

    if (reason == '') {
      setReasonError(true);
    }
  };

  const onChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const message = event.target.value;
    setMessage(message);
    setMessageError(false);

    if (message == '') {
      setMessageError(true);
    }
  };

  const captchaRef = useRef<ReCAPTCHA>(null);
  const onChangeRecaptcha = (data: string | null) => {
    setToken(data ? data : '');
    setReCaptchaError(false);

    if (data === '') {
      setReCaptchaError(true);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const onSubmit: SubmitHandler<IContact> = async (data) => {
    if (reCaptchaError || token === '') {
      setReCaptchaError(true);
      return;
    }

    setIsLoading(true);

    await ContactService.create(data, token).then((result) => {
      setName('');
      setEmail('');
      setReason('');
      setMessage('');
      captchaRef.current?.reset();

      if (result instanceof Error) {
        setHasErrors(true);
        EventTrackingService.track({
          category: gaCategory,
          action: 'enviar-clique',
          label: 'erro-na-requisicao',
        });
      } else {
        EventTrackingService.track({
          category: gaCategory,
          action: 'enviar-clique',
          label: 'sucesso',
        });
        setIsSaved(true);
      }

      setIsLoading(false);
    });
  };

  const captcha = {
    border: reCaptchaError ? '1px solid #d32f2f' : 'none',
    borderRadius: reCaptchaError ? '4px' : 'none',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
  } as const;

  return (
    <>
      <HeaderLandingPage />

      <Grid
        container
        sx={{
          mt: {
            xs: theme.spacing(8),
          },
          p: {
            xs: theme.spacing(2),
            sm: theme.spacing(8),
            md: theme.spacing(10),
            lg: theme.spacing(14),
          },
        }}>
        <Grid container minHeight={'80vh'} gap={theme.spacing(10)}>
          <Grid container flexDirection="column" alignItems="center" gap={theme.spacing(3)}>
            <Typography variant="h3" fontWeight="500">
              Como podemos ajudar?
            </Typography>
            <Divider sx={{ border: '1px solid gray', width: '4%' }} />
            <Typography variant="h5" color={'#666666'} display={isSaved ? 'none' : ''}>
              Preencha o formulário abaixo para entrar em contato conosco.
            </Typography>
          </Grid>
          <Grid container flexDirection="row" justifyContent="center">
            <Grid item md={6}>
              <Grid
                container
                justifyContent="center"
                display={hasErrors ? '' : 'none'}
                sx={{ mb: 3, p: '15px', backgroundColor: 'rgba(255, 0, 0, 0.1)', border: '1px solid #9B0000', borderRadius: '4px' }}>
                <Typography sx={{ opacity: '0.9', color: 'rgba(255, 0, 0, 0.7)' }}>
                  Infelizmente não conseguimos processar sua solicitação. Tente novamente em alguns instantes.
                </Typography>
              </Grid>
              <form onSubmit={handleSubmit(onSubmit)} style={{ display: isSaved ? 'none' : '' }}>
                <Grid container justifyContent="center" alignItems="center" gap={3}>
                  <TextField
                    fullWidth
                    value={name}
                    error={nameError}
                    helperText={nameError ? 'Preencha seu nome' : ''}
                    {...register('name', { required: true })}
                    onChange={onChangeName}
                    inputProps={{ minLength: 3, maxLength: 255 }}
                    name="name"
                    label="Seu Nome"
                  />
                  <TextField
                    fullWidth
                    value={email}
                    error={emailError}
                    helperText={emailError ? 'Preencha seu melhor e-mail' : ''}
                    {...register('email', { required: true })}
                    onChange={onChangeEmail}
                    inputProps={{ maxLength: 255 }}
                    name="email"
                    label="Seu melhor email"
                  />
                  <TextField
                    fullWidth
                    select
                    value={reason}
                    error={reasonError}
                    helperText={reasonError ? 'Escolha uma razão contato' : ''}
                    {...register('reason', { required: true })}
                    onChange={onChangeReason}
                    InputProps={{ sx: { textAlign: 'initial' } }}
                    name="reason"
                    label="Escolha a razão do seu contato">
                    {selectOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    value={message}
                    error={messageError}
                    helperText={messageError ? 'Adicione uma mensagem com no máximo 255 caracteres' : ''}
                    {...register('message', { required: true })}
                    onChange={onChangeMessage}
                    inputProps={{ minLength: 1, maxLength: 255 }}
                    name="message"
                    label={'Escreva sua mensagem aqui...'}
                    multiline
                    rows={4}
                  />
                  <Grid item textAlign="center">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} ref={captchaRef} onChange={onChangeRecaptcha} style={captcha} hl="pt-BR" />
                    <Typography variant="caption" fontWeight="400" color="#d32f2f" display={reCaptchaError ? '' : 'none'}>
                      Informe que você não é um robo
                    </Typography>
                  </Grid>
                  <Button fullWidth type="submit" variant="contained" disabled={isLoading}>
                    ENVIAR
                  </Button>
                </Grid>
              </form>
              <Grid item display={isSaved ? '' : 'none'}>
                <Typography variant="h5" fontWeight="300" align="center">
                  Agradecemos seu contato. Fique de olho no seu e-mail que entraremos em contato em breve.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container flexDirection="row" justifyContent="center" gap={theme.spacing(5)}>
            <Grid item xs={10} md={3} borderRadius="14px" border="1.5px solid #828181">
              <Link href="/sobre" underline="none">
                <Grid item display="flex" flexDirection="column" gap={theme.spacing(1)} p={theme.spacing(3)}>
                  <IconEmail />
                  <Typography color="#000" variant="h6">
                    Sobre o Mande um Cartão
                  </Typography>
                  <Typography color="#666666">Sobre a gente e como virar nosso parceiro</Typography>
                </Grid>
              </Link>
            </Grid>

            <Grid item xs={10} md={3} borderRadius="14px" border="1.5px solid #828181">
              <Link href="/politica-de-privacidade" underline="none">
                <Grid item display="flex" flexDirection="column" gap={theme.spacing(1)} p={theme.spacing(3)}>
                  <IconDocument />
                  <Typography color="#000" variant="h6">
                    Políticas e termos
                  </Typography>
                  <Typography color="#666666">Confira nossas políticas e termos</Typography>
                </Grid>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <FooterLandingPage />
    </>
  );
};
