import { useTheme, Grid, Typography, Button, Link, Divider, TextField } from '@mui/material';

import HomePicture from '../../assets/images/imagem-homem-e-envelope.png';
import Baloes from '../../assets/images/baloes.png';
import UserProfile from '../../assets/images/imagem-padrao-usuario.png';

import { HeaderHome, LinearStepper, Footer } from '../../shared/components';

export const CompartilharConclusao = () => {
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        overflow={'hidden'}
        style={{
          minHeight: '100vh',
        }}>
        <Grid container alignContent={'space-between'}>
          <HeaderHome />

          <Grid container justifyContent={'center'}>
            <Typography overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipses'} variant={'body2'} fontSize={'1.8rem'}>
              Receber Cartão
            </Typography>
          </Grid>

          <Grid container justifyContent={'center'} height={theme.spacing(8)}>
            <LinearStepper activeStep={3} />
          </Grid>

          <Grid
            container
            bgcolor={'transparent'}
            justifyContent={'center'}
            style={{
              backgroundColor: theme.palette.background.paper,
              backgroundImage: `url(${HomePicture})`,
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
            }}>
            <Grid
              container
              direction={'row'}
              xs={8}
              minHeight={theme.spacing(40)}
              borderRadius={theme.spacing(2)}
              padding={theme.spacing(4)}
              marginTop={theme.spacing(2)}
              marginBottom={theme.spacing(2)}
              bgcolor={theme.palette.background.default}>
              <Grid container justifyContent={'space-between'}>
                <Grid container justifyContent={'center'} alignContent={'center'} sm={2}>
                  <img src={Baloes} />
                </Grid>

                <Grid container justifyContent={'center'} flexDirection={'column'} sm={4}>
                  <Grid item>
                    <Typography overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipses'} variant={'body2'} fontSize={'1.6rem'}>
                      Parabéns!!
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Divider
                      style={{
                        margin: '0.5rem',
                        width: '20%',
                        border: '1px solid gray',
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography align={'inherit'} variant={'body1'} fontSize={'1.3rem'}>
                      Ótimo, você realizou todas as etapas, agora enquanto aguarda receber seu cartão você pode compartilhar em seu Instagram!
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sm={5} alignContent={'space-around'} borderRadius={2} border={'1px solid gray'} bgcolor={'white'}>
                  <Grid container height={theme.spacing(8)} gap={1} borderBottom={'1px solid gray'}>
                    <Grid item marginLeft={theme.spacing(1)} display={'flex'} alignItems={'center'}>
                      <img
                        src={UserProfile}
                        style={{
                          width: '3.4rem',
                          height: '3.4rem',
                        }}
                      />
                    </Grid>
                    <Grid item display={'flex'} alignItems={'center'}>
                      <Typography align={'center'} variant={'body1'} fontSize={'1.1rem'}>
                        Anna_
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignContent={'inherit'} justifyContent={'center'} height={theme.spacing(16)} borderBottom={'1px solid gray'}>
                    <Grid item width={'100%'} padding={theme.spacing(2)}>
                      <TextField label={''} multiline rows={4} placeholder={'Type your message here'} variant={'outlined'} fullWidth required />
                    </Grid>
                  </Grid>
                  <Grid container alignContent={'end'} justifyContent={'end'} height={theme.spacing(6)}>
                    <Grid item marginRight={theme.spacing(2)}>
                      <Button
                        size={'large'}
                        variant={'contained'}
                        href={'/home'}
                        LinkComponent={Link}
                        sx={{
                          color: 'white',
                          textTransform: 'capitalize',
                          fontSize: '1rem',
                        }}>
                        Compartilhar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignContent={'end'}>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
