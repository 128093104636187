import { useTheme, Grid, Typography, Button, Link } from '@mui/material';

import HomePicture from '../../assets/images/imagem-homem-e-envelope.png';

import { HeaderHome, Footer, LinearStepper } from '../../shared/components';

export const Home = () => {
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        className={'main'}
        overflow={'hidden'}
        style={{
          minHeight: '100vh',
        }}>
        <Grid container alignContent={'space-between'}>
          <HeaderHome />

          <Grid container justifyContent={'center'}>
            <Typography whiteSpace={'nowrap'} textOverflow={'ellipses'} variant={'body2'} fontSize={'1.8rem'}>
              Bem-vindo ao Mande um Cartão!
            </Typography>
          </Grid>

          <Grid container className={'container-linear-stepper'} justifyContent={'center'} height={theme.spacing(8)}>
            <LinearStepper activeStep={0} />
          </Grid>

          <Grid
            container
            bgcolor={'transparent'}
            justifyContent={'center'}
            style={{
              backgroundColor: theme.palette.background.paper,
              backgroundImage: `url(${HomePicture})`,
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
            }}>
            <Grid
              container
              className={'container'}
              direction={'row'}
              xs={6}
              minHeight={theme.spacing(40)}
              borderRadius={theme.spacing(2)}
              padding={theme.spacing(4)}
              marginTop={theme.spacing(2)}
              marginBottom={theme.spacing(2)}
              bgcolor={theme.palette.background.default}>
              <Grid container justifyContent={'center'}>
                <Typography whiteSpace={'nowrap'} textOverflow={'ellipses'} variant={'body2'} fontSize={'1.6rem'}>
                  Vamos começar?
                </Typography>
              </Grid>
              <Grid container justifyContent={'center'}>
                <Grid item sm={8}>
                  <Typography className={'container-p'} align={'inherit'} variant={'body1'} fontSize={'1.3rem'} textAlign={'center'}>
                    O primeiro passo para entrar no clube é fazer o envio do cartão para uma pessoa. Você deve sortear o beneficiário clicando no botão abaixo,
                    bora?
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={'center'} alignContent={'center'}>
                <Grid item>
                  <Button
                    size={'large'}
                    variant={'contained'}
                    href={'/sortear'}
                    LinkComponent={Link}
                    sx={{
                      color: 'white',
                      textTransform: 'capitalize',
                      fontSize: '1rem',
                    }}>
                    Sortear agora!
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignItems={'end'}>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
