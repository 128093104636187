import { Button, Grid, TextField, Divider, Typography, Link, useTheme } from '@mui/material';

import { LogoImage } from '../../shared/components';
import Background from '../../assets/images/segundo-fundo-landing.jpg';

export const RecoveryPassword = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      justifyContent="center"
      style={{
        minHeight: '100vh',
        backgroundSize: '65%',
        backgroundImage: `url(${Background})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
      <Grid container justifyContent="center" xs={24} sm={12}>
        <LogoImage />
      </Grid>
      <Grid container border="1px solid gray" justifyContent="center" bgcolor="white" xs={3}>
        <Grid container justifyContent="center" alignItems="center" xs={7.5}>
          <Grid item xs={12} alignContent={'center'}>
            <Typography variant="h6" display="flex" justifyContent="center" fontFamily={'Montserrat, sans-serif'} color={'gray'} marginBottom={2}>
              <strong>Send a recovery link</strong>
            </Typography>
            <Typography fontSize={'14px'} justifyContent="center" fontFamily={'Montserrat, sans-serif'} color={'gray'}>
              We ll send you a recovery link to
            </Typography>
            <TextField fullWidth placeholder="Enter email" margin="normal" />
            <Grid container marginTop={3} xs={12}>
              <Button color="primary" variant="contained" sx={{ width: '100%' }}>
                Send link
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item>
            <Button variant="text">Return to log in</Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignContent={'end'}>
        <Grid container alignContent={'end'} bgcolor="primary.main" justifyContent={'space-around'} minHeight={theme.spacing(4)}>
          <Grid item>
            <Button variant="text" size="small" sx={{ color: 'white' }} href="/dashboard">
              Política de Privacidade
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text" size="small" sx={{ color: 'white' }} href="/dashboard" LinkComponent={Link}>
              Mande um Cartão &reg; {new Date().getFullYear()}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text" size="small" sx={{ color: 'white' }} href="/dashboard">
              Termos de uso
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
