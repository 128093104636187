import { styled } from '@mui/material/styles';
import { Grid, StepConnector, stepConnectorClasses, Stack, Stepper, Step, StepLabel, StepIconProps } from '@mui/material';
import './LinearStepper.css';

import Check from '@mui/icons-material/Check';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#6C63FF',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#6C63FF',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 14,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#6C63FF',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#6C63FF',
    zIndex: 1,
    fontSize: 22,
  },
  '& .QontoStepIcon-circle': {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}

const steps = ['Sortear Alguém', 'Enviar Cartão', 'Receber Cartão'];

interface LinearStepperProps {
  activeStep: number;
}

export const LinearStepper = (props: LinearStepperProps) => {
  const { activeStep } = props;

  return (
    <Grid item className={'linear-stepper'} width={'48rem'}>
      <Stack sx={{ width: '100%' }}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Grid>
  );
};
