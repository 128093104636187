import { Grid } from '@mui/material';
import iconRaffle from '../../../assets/images/icone-sorteio.png';

export const IconRaffle = () => {
  return (
    <Grid item>
      <img src={iconRaffle} alt="icone-sorteio" width="150px" />
    </Grid>
  );
};
