import { useTheme, Grid, Typography, Divider } from '@mui/material';

import { HeaderLandingPage, FooterLandingPage, TextAbout } from '../../shared/components';

import Card from '../../assets/images/cartao.png';

export const AboutPage = () => {
  const theme = useTheme();

  return (
    <>
      <HeaderLandingPage />

      <Grid
        container
        justifyContent={'center'}
        sx={{
          pt: {
            xs: theme.spacing(16),
          },
          pb: {
            xs: theme.spacing(16),
          },
        }}>
        <Grid
          container
          justifyContent={'center'}
          minHeight="100vh"
          gap={theme.spacing(5)}
          sx={{
            backgroundImage: `url(${Card})`,
            backgroundPosition: 'bottom',
            backgroundSize: { xs: '440px', sm: '690px', md: '868px', xl: '968px' },
            backgroundRepeat: 'no-repeat',
          }}>
          <Grid container flexDirection={'column'} alignItems={'center'} gap={theme.spacing(3)}>
            <Typography variant="h3" fontWeight="500" paragraph>
              Sobre
            </Typography>
            <Divider sx={{ border: '1px solid gray', width: '5%' }} />
          </Grid>

          <Grid item display={'flex'} alignContent={'end'} justifyContent={'center'}>
            <Grid
              item
              display={'flex'}
              flexDirection={'column'}
              alignContent={'center'}
              bgcolor={'#F6F5F5'}
              borderRadius={'15px 15px 0px 0px'}
              boxShadow={'6px -5px 10px -7px gray'}
              gap={3}
              xs={10}
              sx={{
                pt: {
                  xs: '18px',
                },
                mb: {
                  xs: theme.spacing(24),
                  sm: theme.spacing(40),
                  md: theme.spacing(50),
                },
                width: {
                  xs: '366px',
                  sm: '574px',
                  md: '720px',
                  xl: '805px',
                },
                height: {
                  xs: '2000px',
                  sm: '1330px',
                  md: '1140px',
                  xl: '1080px',
                },
              }}>
              <Grid item display={'flex'} justifyContent={'center'} xs={12}>
                <TextAbout />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <FooterLandingPage />
    </>
  );
};
